<template>
  <div
      class="flex flex-col items-center justify-between rounded-md mx-2 hover:bg-white h-full cursor-pointer pb-2"
      :class="
      classifiedAd.is_featured
        ? 'border-a-primary border-2 hover:shadow-lg'
        : 'border border-transparent hover:border-a-neutral-dim hover:shadow-md'
    "
  >
    <!-- Card Content -->
    <div
        class="flex flex-col w-full h-full"
    >
      <p
          class="w-full bg-a-primary text-sm font-semibold text-center"
          v-if="classifiedAd.is_featured"
      >
        Featured Ad
      </p>
      <NuxtImg
          :src="classifiedAd.image"
          class="object-cover h-40"
          :class="
          classifiedAd.is_featured
            ? ''
            : 'rounded-t-md'
        "
          alt=""
      />
      <div class="flex flex-1 flex-col w-full px-2">
        <!--  Price and Published Row  -->
        <div
            class="flex flex-row items-center justify-between text-xs my-1.5"
        >
          <p
              class="font-semibold text-a-secondary-dark text-xl"
          >
            ${{ classifiedAd.price }}
          </p>
          <div class="flex flex-row items-center justify-end font-semibold text-xs text-a-neutral-light">
            {{
              classifiedAd.published_at_formatted
            }}
            <BaseTooltip
                trigger-icon="material-symbols:info-rounded"
                :tooltipText="classifiedAd.published_at"
            />
          </div>
        </div>
        <!--  Price and Published Row Ends -->
        <!--   Title     -->
        <p
            class="font-semibold text-md my-1"
        >
          {{ excerptedTitle }}
        </p>
        <!--   Title Ends     -->
        <!--   Location     -->
        <div
            class="flex flex-row items-center justify-start w-full text-a-neutral-lightest font-semibold text-xs my-0.5"
        >
          <Icon
              name="mdi:map-marker"
              class="text-lg mr-1 text-a-error-dark"
          />
          <p
              class="text-a-neutral-light"
          >
            {{
              classifiedAd.city
            }},&nbsp;{{
              classifiedAd.state
            }}
          </p>
        </div>
        <!--   Location Ends     -->
      </div>
    </div>
    <!-- Card Content Ends-->
    <!-- Card Meta -->
    <div
        class="flex flex-row items-center justify-between w-full px-1 my-2"
    >
      <div
          class="bg-a-secondary-lightest text-a-secondary border-a-secondary border-2 rounded text-xs px-1 mx-0.5 my-1 py-0.5"
      >
        {{ classifiedAd.category }}
      </div>
      <div
          class="flex flex-row items-center justify-start font-semibold text-a-neutral-lightest text-xs"
      >
        <Icon
            name="material-symbols:photo-camera-rounded"
            class="text-lg mr-1 text-a-secondary"
        />
        <p class="text-a-neutral-light">
          {{
            classifiedAd.images_count
          }}
          images
        </p>
      </div>
    </div>
    <!-- Card Meta Ends -->
  </div>
</template>

<script setup lang="ts">
import type {HomeClassifiedAd} from "~/types/main";

const props = withDefaults(defineProps<{
  classifiedAd: HomeClassifiedAd
}>(),{})

const excerptedTitle = computed(()=>{
  if(props.classifiedAd.title.length <= 70){
    return props.classifiedAd.title
  }
  return (props.classifiedAd.title as string).slice(0,70)+'...';
})
</script>
